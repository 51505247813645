<template>
  <div class="segment" v-if="config">
    <div class="segment-content">
      <FormContainer
        @finish-form-values="isDone"
        @config-with-values="safeData"
        @previous-page="changeConfig"
        @close="close"
        :config="config"
        :lang="$store.getters.language.lang"
        :next-step="nextStep"
        :has-close-button="true"
        :has-back-button="true"
      />
    </div>
  </div>
</template>

<script>
import { CheckIn, FormContainer, Settings } from '@seliaco/red-panda'
import { CheckInEvent } from '@/types/events'

export default {
  name: 'CheckInForm',
  components: {
    FormContainer
  },
  props: {
    counterData: String
  },
  computed: {
    counter () {
      return this.counterData ? JSON.parse(this.counterData) : {}
    }
  },
  data () {
    return {
      config: null,
      nextStep: false
    }
  },
  mounted () {
    if (!this.counter) {
      this.$router.push({ name: 'CheckIn' })
    }
    this.getStep()
  },
  methods: {
    async getStep () {
      const key =
        this.counter.type === 'WELLNESS'
          ? 'CHECK_IN_WELLNESS_CONFIGURATION'
          : 'CHECK_IN_ANXIETY_CONFIGURATION'

      const options = await Settings.get(key)
      this.config = options.parse_value
    },
    changeConfig () {
      this.config = JSON.parse(localStorage.getItem('selia-check-in-form'))
    },
    callSegmentEvent (value) {
      const month = new Date(new Date().getFullYear(), (value.month - 1)).toLocaleString(`${this.$store.getters.language.lang}`, { month: 'short' })
      let score = value.score
      if (!value.score) {
        score = '+'
      }
      const body = {
        user: this.$store.getters['auth/user'],
        month: month,
        month_number: value.month,
        type: value.type,
        score: score
      }
      CheckInEvent.Checkin_FillUp_Successful(body)
    },
    safeData (val) {
      localStorage.setItem('selia-check-in-form', JSON.stringify([...val]))
      this.nextStep = true
      setTimeout(() => {
        this.nextStep = false
      }, 300)
    },
    close () {
      this.$router.go(-1)
      CheckInEvent.Checkin_Exit_Form({ user: this.$store.getters['auth/user'] })
    },
    isDone (form) {
      form = form.map(option => {
        return {
          question: option.key,
          value: option.response?.value >= 0 ? option.response.value : option.response,
          type: option.response?.value >= 0 ? 'NUMBER' : 'TEXT'
        }
      })
      const body = {
        checking_id: this.counter.id,
        type: this.counter.type,
        answers: form
      }
      CheckIn.evaluateWellnessCheckIn(body)
        .then(() => {
          CheckIn.getCheckInAnswerData(body.checking_id)
            .then((response) => {
              this.callSegmentEvent(response)
              this.$router.push({
                name: this.counter.fromSignUp ? 'Home' : 'CheckIn',
                query: {
                  type: response.type,
                  showModal: 'visible',
                  data: JSON.stringify({
                    ...response,
                    month: this.$moment.locale(this.$store.getters.language.lang) && this.$moment.months(response.month - 1)
                  })
                }
              })
            })
            .catch((e) => {
              this.$toast({
                text: e.message || this.$translations.error.default,
                severity: 'error'
              })
            })
        })
        .catch((e) => {
          this.$toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })
    }
  }
}
</script>
